@import "../variables.scss";

#progressbar {
  background-color: $progress-bar-bg-color;
  padding: 3px;
  margin-top: 5px;
  border-radius: 13px;
  margin-bottom: 18px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  > div {
    background-color: $primary-color;
    height: 10px;
    border-radius: 13px;
  }
}
.skill-value {
  display: flex;
  justify-content: flex-end;
}
.side-spacing-my-skill {
  margin: 0px 20px !important;
}
.my-skill-heading {
  text-align: center;
}
.my-skill-text {
  text-align: center;
}
