@import "../variables.scss";

.my-image-paper-size {
  height: 400px;
  width: 290px;
}
.heading-ani-color {
  color: $primary-color;
}
.image-size {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  display: block;
}
.image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
}
.about-me-wrapper {
  display: flex;
  flex-direction: column;
  margin: 1%;
}
.side-spacing-aboutme-text {
  padding-top: 10px;
}
.aboutme-text-style {
  margin: 0;
  margin-left: 5%;
  font-size: 35px;
}
.side-spacing-aboutme {
  margin: 7px 5%;
}
.about-me-container {
  margin-top: 75px !important;
}
.i-am-style {
  font-size: 30px;
  padding-top: 2px;
}
.Typo {
  margin: 0 !important;
}
a {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &:focus {
    text-decoration: none;
    color: inherit;
  }
  &:active {
    text-decoration: none;
    color: inherit;
  }
}
.resume-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
}
.resume-btn {
  background-color: $primary-color !important;
  color: $secondary-color !important;
}
.swing {
  animation: swing ease-in-out 1s infinite alternate;
  transform-origin: center -20px;
  float: left;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.swing img {
  border: 5px solid #f8f8f8;
  display: block;
}
.swing:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border: 5px solid white;
  top: -23px;
  left: 44%;
  z-index: 0;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
}
/* nail */
.swing:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  top: -28px;
  left: 50%;
  z-index: 5;
  border-radius: 50% 50%;
  background: black;
}

@keyframes swing {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}
.about-me-heading {
  margin-bottom: 20px !important;
}
.high-light-text {
  color: $primary-color;
  font-weight: 600;
}
