@import "../variables.scss";

.card-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.card-heading {
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
}
.icon-size {
  height: 70px;
  width: 70px;
}
.icon-style {
  width: 100% !important;
  height: 100%;
  color: $secondary-color;
}
.card-color:hover {
  background-color: $primary-color !important;
  color: $secondary-color !important;
}
.imageContner-project {
  height: 120px;
  width: 120px;
}
.image-size-project {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;
  background-color: $primary-background-color;
  border: 6px solid $primary-color;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.project-card-spacing {
  margin: 1% 30px;
  margin-left: 20px;
  margin-right: 20px;
}
.project-heading-container {
  max-height: 100px;
  height: 100px;
  overflow: scroll;
  width: 100%;
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $secondary-background-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}
.no-margin {
  margin-bottom: 0px !important;
}
.project-high-light-text {
  font-weight: 600;
  color: $primary-background-color;
}
