@import "./variables.scss";

body {
  font-family: "Ubuntu", sans-serif;
}
header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
}
.site-background {
  background-color: $site-bg-color;
}
.primary-color {
  color: $primary-color;
  font-size: larger !important;
  font-weight: 900 !important;
}
.side-spacing {
  margin: 0 5px !important;
}
.section-background-color {
  background-color: $primary-background-color;
}
.heading-color {
  color: $secondary-color;
}
.peragraph-fontsize {
  font-size: 18px;
}
.item-position {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.paper-size {
  margin: 15px 4%;
  padding: 5px;
  background-color: $paper-bg-color !important;
}
.heading-alignment {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.heading-height {
  margin-top: 3% !important;
  height: 100px;
}
.card-color {
  background-color: $secondary-background-color !important;
  height: 100%;
  width: 100%;
}
.card-color-two {
  background-color: $primary-color !important;
}
.section-heading {
  font-size: 20px;
  font-weight: 600;
}
.scrollTop {
  position: fixed;
  width: 40px;
  bottom: 20px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  height: 40px;
  color: $primary-color;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  &:hover {
    opacity: 1;
  }
}
.footer-width {
  width: 100%;
}
.menu-container {
  background-color: $primary-background-color;
}
.icon-heading-container {
  display: flex;
  align-items: center;
}
.hover-effect {
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.04);
  }
}
.hover-high-effect {
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.2);
  }
}
.page-sub-heading {
  text-align: center;
  max-width: 600px;
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 60%;
    height: 3px;
    background: $primary-color;
    left: 105%;
    top: 50%;
    position: absolute;
  }
  &:after {
    content: "";
    display: block;
    width: 60%;
    height: 3px;
    background: $primary-color;
    right: 105%;
    top: 50%;
    position: absolute;
  }
}
