@import "../variables.scss";

input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0 0 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type="email"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0 0 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.nameEmailContainer {
  display: flex;
  margin-bottom: 8px;
}
.nameContainer {
  margin-right: 20px;
  width: 50%;
}
.emailContainer {
  width: 50%;
}
textarea {
  width: 100%;
  height: 100px;
  margin-top: 8px;
  padding: 14px 20px;
}
.messageContainer {
  margin: 8px 0;
}
small {
  color: $error-color;
}
.errorClass {
  border: 2px solid $error-color !important;
}
.send-btn {
  background-color: $primary-color !important;
  color: $secondary-color !important;
}
.circular-progress {
  color: $secondary-color !important;
}
.send-btn-container {
  margin-top: 3%;
}
.ok-btn {
  background-color: $primary-color !important;
  color: $secondary-color !important;
}

textarea {
  resize: vertical;
  height: 100%;
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
