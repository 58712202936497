@import "../variables.scss";

.flip-card {
  background-color: transparent;
  height: 240px;
  margin-bottom: 3%;
  perspective: 1000px;
  &:hover {
    .flip-card-inner {
      transform: rotateY(180deg);
    }
  }
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.flip-card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: black;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: $primary-color;
  color: white;
  border-radius: 5px;
  transform: rotateY(180deg);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.icon-size {
  height: 70px;
  width: 70px;
}
.flip-card-front ::before {
  opacity: 0.8;
}
.card-spacing {
  margin: 5% 30px !important;
}
.over-lay {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}
.flip-card-back-subheading {
  margin: 0 12px;
}
