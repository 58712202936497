@import "../variables.scss";

.course-slider {
  margin: auto;
  width: 85vw;
  margin-bottom: 40px;
}
.course-slider-item {
  width: 90% !important;
  margin-left: 20px !important;
  background-color: $primary-color;
}
.slick-dots li.slick-active button:before {
  color: $primary-color !important;
}
.slick-dots li button:before {
  color: white !important;
}

.clear-fix:after {
  display: block;
  clear: both;
  content: "";
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

$color-box-1: #0f9bc0;
$color-box-2: rgba(34, 65, 154, 0.8);
$color-box-3: $primary-background-color;
$color-box-4: #e660e3;
$color-box-5: $primary-color;
$color-box-6: #9b1308;
$color-box-7: #de8b3e;

.card__collection {
  position: relative;
  display: block;
  overflow: hidden;

  .cards {
    display: block;
    background-size: cover;
    float: left;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    background-position: center;
    margin: 5px;
  }

  .cards--one {
    backface-visiblility: hidden;
    &:hover {
      &:after {
        bottom: -20px;
      }

      &:before {
        bottom: -10px;
      }

      .cards--one__rect {
        left: 45%;

        &--back {
          left: 50%;
        }
      }
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 70px;
      transform: rotate(-3deg);
      background: $color-box-4;
      position: absolute;
      bottom: -80px;
      left: 0;
      right: -10px;
      z-index: 9;
      transition: all 0.2s ease-in;
      transition-delay: 0.3s;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 80px;
      transform: rotate(-3deg);
      bottom: -90px;
      left: 0;
      background: #fff;
      right: -10px;
      z-index: 5;
      transition: all 0.2s ease-in;
      transition-delay: 0.1s;
    }

    &__rect {
      color: #fff;
      text-transform: uppercase;
      font-size: 18px;
      background: $color-box-1;
      width: 126px;
      height: 55px;
      transform: skewY(5deg);
      position: absolute;
      display: block;
      top: 60%;
      left: -45%;
      z-index: 1;
      line-height: 3.3rem;
      text-align: center;
      transition: all 0.2s ease-in;

      &--back {
        display: block;
        background: $color-box-2;
        width: 126px;
        height: 55px;
        transform: skewY(7deg);
        position: absolute;
        top: 65%;
        left: -50%;
        transition: all 0.2s ease-in;
        transition-delay: 0.3s;
      }

      a {
        transform: skewY(-7deg);
        position: relative;
      }
    }
  }

  .cards--two {
    position: relative;
    backface-visibility: hidden;

    a {
      position: absolute;
      top: 83%;
      left: -100%;
      text-transform: capitalize;
      color: #fff;
      font-size: 20px;
      z-index: 8;
      transition: all 0.6s ease;
    }

    &:hover {
      a {
        left: 8%;
      }

      img {
        transform: translateY(-15px);
      }

      .cards--two__rect {
        top: 50%;

        &:before {
          transform: translateY(15px);
        }
      }

      li {
        transform: translateY(0);
      }

      .cards--two__tri {
        right: -40%;

        &:before {
          right: -312px;
        }
      }
    }

    img {
      transition: all 0.2s ease;
    }

    &__tri {
      border-top: 220px solid transparent;
      border-bottom: 190px solid transparent;
      border-right: 288px solid #fff;
      opacity: 0.8;
      position: absolute;
      display: block;
      top: 0;
      right: -100%;
      transition: all 0.3s ease-in-out;

      &:before {
        border-top: 220px solid transparent;
        border-bottom: 190px solid transparent;
        border-right: 288px solid $color-box-3;
        position: absolute;
        content: "";
        display: block;
        top: -220px;
        right: -612px;
        transition: all 0.3s ease-in-out;
        transition-delay: 0.2s;
      }
    }

    &__rect {
      width: 750px;
      height: 200px;
      background: #fff;
      display: block;
      position: absolute;
      top: 175%;
      left: -78%;
      transform: rotate(30deg);
      z-index: 5;
      opacity: 0.8;
      transition: all 0.3s ease-in-out;

      &:before {
        content: "";
        display: block;
        width: 100%;
        position: relative;
        height: 100%;
        background: $color-box-5;
        transform: translateY(200px);
        z-index: 2;
        transition: all 0.3s ease-in-out;
        transition-delay: 0.1s;
      }
    }

    ul {
      list-style: none;
      position: absolute;
      bottom: 0;
      left: 10px;
      z-index: 9;

      li {
        display: inline-block;
        font-size: 16px;
        margin: 7px;
        color: #fff;
        transition: all 0.2s ease-in-out;
        transform: translateY(100px);

        &:nth-child(2) {
          transition-delay: 0.2s;
        }

        &:nth-child(3) {
          transition-delay: 0.3s;
        }

        &:nth-child(4) {
          transition-delay: 0.4s;
        }
      }
    }
  }

  .cards--three {
    position: relative;
    z-index: 10;

    &:hover {
      .cards--three__rect-1 {
        left: 10%;

        .shadow-1 {
          left: -20%;
        }
      }

      .cards--three__rect-2 {
        left: 60%;

        &:before {
          left: -100%;
        }

        &:after {
          left: 80%;
        }

        .shadow-2 {
          left: -10%;
        }
      }

      .cards--three__circle {
        transform: scale(1);

        &:before {
          transform: scale(0.9);
        }
      }

      .cards--three__list {
        li {
          transform: translateX(0);
        }
      }
    }

    &__rect-1 {
      background: #fff;
      width: 200px;
      height: 110px;
      transform: skewX(-20deg);
      display: block;
      position: absolute;
      top: 73%;
      opacity: 0.8;
      left: -100%;
      z-index: 8;
      transition: all 0.5s ease-in;

      p {
        font-size: 20px;
        color: #fff;
        transform: skewX(20deg);
        line-height: 6rem;
      }

      .shadow-1 {
        background: $color-box-6;
        width: 230px;
        height: 100px;
        display: block;
        position: absolute;
        left: -200%;
        z-index: -1;
        transition: all 0.3s ease-in;
      }
    }

    &__rect-2 {
      width: 100px;
      height: 70px;
      background: #fff;
      position: absolute;
      top: 65%;
      left: 1000%;
      opacity: 0.8;
      transform: skewX(-20deg);
      z-index: 8;
      transition: all 0.5s ease-in;

      &::before {
        content: "";
        display: block;
        width: 50px;
        height: 40px;
        background: $color-box-3;
        opacity: 1;
        position: absolute;
        left: 500%;
        top: 10%;
        transition: all 0.3s ease-in;
      }

      &::after {
        content: "";
        display: block;
        width: 50px;
        height: 40px;
        background: $color-box-6;
        opacity: 1;
        position: absolute;
        left: 500%;
        top: 114%;
        transition: all 0.5s ease-in;
        transition-delay: 0.2s;
      }

      .shadow-2 {
        background: $color-box-3;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: -10%;
        left: 500%;
        transition: all 0.5s ease-in;
        transition-delay: 0.2s;
      }
    }

    &__circle {
      width: 220px;
      height: 220px;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      top: -15%;
      left: 50%;
      opacity: 0.8;
      transform: scale(0);
      transition: all 0.3s ease;

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: $color-box-6;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease;
        transition-delay: 0.2s;
      }
    }

    &__list {
      list-style: none;
      position: absolute;
      top: -9px;
      right: 7px;
      padding-top: 60px;
      transition: all 0.4s ease;

      li {
        display: inline-block;
        margin: 4px;
        color: #fff;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: rgba(87, 204, 253, 0.9);
        text-align: center;
        line-height: 1.7rem;
        font-size: 12px;
        transition: all 0.3s ease;
        transform: translateX(500%);

        &:nth-child(1) {
          transition-delay: 0.2s;
        }

        &:nth-child(2) {
          transition-delay: 0.3s;
        }

        &:nth-child(3) {
          transition-delay: 0.4s;
        }
      }
    }
  }
}
.contact-value p {
  margin: 0 !important;
}
