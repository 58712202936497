@import "../variables.scss";

.top-bar-container {
  margin-top: 5px;
  height: 58px;
  top: 0;
  position: fixed;
  z-index: 9;
  width: 85%;
  display: flex;
  justify-content: space-evenly;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media (max-width: 960px) {
  .top-bar-container {
    width: 100%;
    margin-top: 0;
  }
}

.top-bar-item {
  display: flex;
  justify-content: center;
  height: 100%;
}
.mobile-top-bar-item {
  display: flex;
  justify-content: center;
  height: 100%;
}
.item-btn {
  height: 100%;
  width: 130px;
  margin: 0 10px;
  color: $secondary-color;
}
.top-bar-text {
  color: $secondary-color;
  margin: 12px;
  text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
}
.top-bar-text-mobile {
  color: $secondary-color;
}

.mobile-button-container {
  display: flex;
  justify-content: flex-end;
}
.mobile-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $secondary-color;
}
.mobile-top-bar-icon {
  height: 28px;
  width: 26px;
  color: $secondary-color;
}
.mobile-top-bar-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
}
Select {
  background-color: aqua !important;
}
.top-bar-item-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.top-bar-item-container a {
  display: block;
  position: relative;
  text-align: center;
  background: $primary-color;
  color: #fff;
  margin: 25px 0;
  padding: 10px 0;
  font-size: 20px;
  font-family: cursive;
  text-decoration: none;
  border: 2px dashed white;
  outline: 6px solid $primary-color;
  backface-visibility: hidden;
  transition: transform 0.2s ease-in-out;
  z-index: 2;
}
.top-bar-item-container a:hover {
  text-decoration: underline;
  outline: 6px solid $primary-color;
}

.top-bar-item-container a:before {
  top: -5px;
  bottom: -5px;
  left: -8px;
  right: -8px;
  box-shadow: 0 0 10px 1px #000;
  border-radius: 0px;
  content: "";
  position: absolute;
  z-index: -2;
}

.top-bar-item-container a:nth-child(odd) {
  transform: rotate(-2deg);
}
.top-bar-item-container a:nth-child(even) {
  transform: rotate(2deg);
}

.top-bar-item-container a:hover:nth-child(odd),
.top-bar-item-container a:hover:nth-child(even) {
  transform: rotate(0deg);
}
a {
  text-decoration: none !important;
  cursor: pointer;
}
.top-mobile-container {
  background-color: $primary-color !important;
}
